import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import { instance } from "../utils/api";
import {  toast } from 'react-toastify'
const ResellerDialog = ({ open, onClose, role, dynamicTitle, editMode, row }) => {
    const [contactEmail, setContactEmail] = useState('');
    const [contactNumber, setContactNumber] = useState('');
    const [name, setName] = useState('');
    const [commissionPercentage, setCommissionPercentage] = useState();
    
    useEffect(() => {
      if(editMode){
        setContactEmail(row?.original.email);
        setContactNumber(row?.original.phone);
        setName(row?.original.name);
        setCommissionPercentage(row?.original.commission_in_perc);
      }else{
        setContactEmail('');
        setContactNumber('');
        setName('');
        setCommissionPercentage('');
      }
    }, [row, editMode])

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\d{10}$/;
const Token = localStorage.getItem("Token")
    const handleSave = async () => {
        try {
          if (
          !name.trim() || 
          !contactEmail.trim() || 
          !contactNumber.trim()) {
            toast.error('All fields are mandatory!', { position: 'top-center' });
            return;
          }
    
          if (!emailRegex.test(contactEmail)) {
            toast.error('Invalid email format!', { position: 'top-center' });
            return;
          }
    
          if (!phoneRegex.test(contactNumber)) {
            toast.error('Invalid phone number format!', { position: 'top-center' });
            return;
          }

          const data = {
              name: name,
              role: role,
              email: contactEmail,
              phone: contactNumber,
            }

          if(role === "Reseller"){data.commission_in_perc = commissionPercentage};

          let response
          
          if(editMode){
            response = await instance.put(`/Superadmin/Author/update/${row?.original._id}`, data,
            {
              headers: {
                Authorization: `Bearer ${Token}`,
                'Content-Type': 'application/json',
              },
            });
          }else{
          response = await instance.post("/Superadmin/Author/create", data,
            {
              headers: {
                Authorization: `Bearer ${Token}`,
                'Content-Type': 'application/json',
              },
            });
          }
            setContactEmail('');
            setContactNumber('');
            setName('');
            setCommissionPercentage('');
            toast.success('Updated successfully!', { position: 'top-center' });
            onClose()
            console.log(response.data);
        //   console.log('Reseller created successfully!');
          
        } catch (error) {
          console.error('Error:', error);
          
        } finally {
          // onClose();
        }
      };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle style={{textAlign:'center'}}>{dynamicTitle}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{marginTop:"1px"}}>

        <Grid item xs={12} >
            <div className="app-form-fields">
              <FormControl fullWidth>
                <TextField
                  size="medium"
                  required
                  id="outlined-basic"
                  label="Name"
                  placeholder="Vibho"
                  variant="outlined"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </FormControl>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="app-form-fields">
              <FormControl fullWidth>
                <TextField
                  size="medium"
                  required
                  id="contact_email"
                  label="Contact Email"
                  placeholder="hr@vibhotech.com"
                  variant="outlined"
                  value={contactEmail}
                  onChange={(e) => setContactEmail(e.target.value)}
                />
              </FormControl>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="app-form-fields">
              <FormControl fullWidth>
                <TextField
                  size="medium"
                  required
                  id="contact_number"
                  label="Contact Number"
                  placeholder="9898989898"
                  type='number'
                  onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                  variant="outlined"
                  value={contactNumber}
                  onChange={(e) => setContactNumber(e.target.value)}
                />
              </FormControl>
            </div>
          </Grid>
          { role === "Reseller" &&
            <Grid item xs={12}>
            <div className="app-form-fields">
              <FormControl fullWidth>
                <TextField
                  size="medium"
                  required
                  id="commission_in_perc"
                  label="Commission Percentage"
                  placeholder=""
                  variant="outlined"
                  value={commissionPercentage}
                  onChange={(e) => setCommissionPercentage(e.target.value)}
                />
              </FormControl>
            </div>
            </Grid>
          }
          
          <Grid item xs={12}>
            <Button variant="contained" color="primary" onClick={handleSave}>
           
              {editMode ? 'Update' : 'Save'}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
      
    </Dialog>
    
  );
};
  
export default ResellerDialog;
