import { BrowserRouter, Routes, Route } from "react-router-dom"
import "./styles/main.scss"
import ConsumersList from './pages/ConsumersList'

import Login from './pages/auth/Login'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import ConsumerDetails from './pages/ConsumerDetails'
import Inactiveconsumers from "./pages/Inactiveconsumers"
import { jwtDecode as decode } from "jwt-decode"
import { createContext, useEffect, useState } from "react"
import Reseller from "./pages/Reseller"
import SalesRepresentative from "./pages/SalesRepresentative"
import ConsumerCount from "./pages/ConsumerCount"
import Dashboard from "./pages/Dashboard"
import ConsumerTracking from "./pages/ConsumerTracking"
import ConsumersCompanyTracking from "./pages/ConsumersCompanyTracking"

export const AuthContext = createContext()

function App() {
	const [userdata, setUserdata] = useState(undefined)

	useEffect(() => {
		const token = localStorage.getItem("Token")
		if (token) {
			const decoded = decode(token)
			const expDate = new Date(decoded.exp * 1000)
			const dateDiffHours = (expDate - new Date())/ (1000 * 60 * 60);
			console.log(dateDiffHours);
			if(dateDiffHours < 1){
				setUserdata(undefined)
			}else{
				decoded && decoded.user && setUserdata(decoded.user)
			}
		}
	}, [])

	console.log({ userdata })

	// Logged in users
	if (userdata) {
		return (
			<AuthContext.Provider value={{
				state: { userdata },
				actions: { setUserdata }
			}}>
				<BrowserRouter >
					<ToastContainer />
					<Routes>
						<Route path="/" element={<ConsumersList />} />
						<Route path="/consumers" element={<ConsumersList />} />
						<Route path="/inactive-consumers" element={<Inactiveconsumers />} />
						{userdata.role === "Master" && <Route path="/consumerslist" element={<ConsumerCount />} />}
						{userdata.role === "Master" && <Route path="/Reseller" element={<Reseller />} />}
						{userdata.role === "Master" && <Route path="/sales-representative" element={<SalesRepresentative />} />}
						{userdata.role === "Master" && <Route path="/dashboard" element={<Dashboard />} />}
						{userdata.role === "Master" && <Route path="/consumerTracking" element={<ConsumerTracking />} />}
						{userdata.role === "Master" && <Route path="/consumersCompanyTracking/:domain" element={<ConsumersCompanyTracking />} />}
						<Route path='/consumerview/:id/:domain' element={<ConsumerDetails />} />
					</Routes>
				</BrowserRouter>
			</AuthContext.Provider>
		)
	}

	// Users without login
	return (
		<AuthContext.Provider value={{
			state: { userdata },
			actions: { setUserdata }
		}}>
			<BrowserRouter >
				<ToastContainer />
				<Routes>
					<Route path="/" element={<Login />} />
					<Route path="*" element={<Login />} />
				</Routes>
			</BrowserRouter>
		</AuthContext.Provider>
	)
}

export default App
