import React, { useEffect, useState } from "react"
import { Button, IconButton } from "@mui/material"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import Popover from "@mui/material/Popover"
import { NotificationsNoneOutlined } from "@mui/icons-material"
import { Link, useNavigate } from "react-router-dom"
import { instance } from "../utils/api"
import { useParams } from "react-router-dom"
import { useLocation } from "react-router-dom"
import ResellerDialog from './ResellerPopup'
import { ToastContainer, toast } from 'react-toastify'
import { VscVerifiedFilled } from "react-icons/vsc"
import defaultPicture from '../assets/defaultpicture.png'
import { useContext } from "react"
import { AuthContext } from "../App"

function Header() {
	const navigate = useNavigate()
	const location = useLocation()
	const Token = localStorage.getItem("Token")
	const userRole = localStorage.getItem("userRole")

	const { state, actions } = useContext(AuthContext)

	const userdata = state.userdata

	const [isDialogOpen, setDialogOpen] = useState(false)

	const openDialog = () => setDialogOpen(true)
	const closeDialog = () => setDialogOpen(false)

	const [isDialOpen, setDialOpen] = useState(false)
	const openDial = () => setDialOpen(true)
	const closeDial = () => setDialOpen(false)
	
	const role = userdata.role
	const name = userdata.name


	// const [name, setName] = useState('');
	// const [phone, setPhone] = useState('');
	// const { agentId } = useParams();

	// useEffect(() => {
	//     async function fetchProfileData() {
	//         try {
	//             const response = await instance.get(`agents/${agentId}`);
	//             const profileData = response.data;
	//             setName(profileData.name);
	//             setPhone(profileData.phone);
	//         } catch (error) {
	//             console.error('Error fetching profile data:', error);
	//         }
	//     }

	//     fetchProfileData();
	// }, [agentId]);

	const NavigateChangePassword = () => {
		navigate("/changepassword")
	}
	const NavigateLogin = () => {
		navigate("/login")
	}
	const handleProfile = () => {
		navigate("/profile")
	}
	const handleLogout = () => {
		localStorage.removeItem("Token")
		actions.setUserdata(undefined)
		navigate("/")
	}
	const [anchorEl, setAnchorEl] = React.useState(null)

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}
	const [anchorEl2, setAnchorEl2] = React.useState(null)
	const open2 = Boolean(anchorEl2)
	const handleClick2 = (event) => {
		setAnchorEl2(event.currentTarget)
	}
	const handleClose2 = () => {
		setAnchorEl2(null)
	}
	const open = Boolean(anchorEl)
	const id = open ? "simple-popover" : undefined
	const cl = "app-layout-header"

	const [image, setImage] = useState(null)
	const ID = localStorage.getItem("ID")




	return (
		<>
			<div className={`${cl}`}>
				<div className={`${cl}__wrapper`}>
					<div className={`${cl}__left`}>
						<img className="app-logo" src="/images/vibhohcm-logo.svg" />
						<ul className={`${cl}__menu`}>
							<li className={`${cl}__menu--item`}>
								  <Link to="/dashboard">
                                    <Button variant="text" type="primary" className={`menu-btn ${location.pathname === "/dashboard" ? "--useractive" : ""
											}`}>
                                        <img src='/images/icons/dashboard.svg' />
                                        <span className="app-header-btn-text">
                                            Dashboard
                                        </span>
                                    </Button>
                                </Link>
								<Link to="/consumers">
									<Button
										variant="text"
										type="primary"
										className={`menu-btn ${location.pathname === "/consumers" ? "--useractive" : ""
											}`}
									>
										<span className="app-header-btn-text">
											Active Consumers
										</span>
									</Button>
								</Link>
								<Link to="/inactive-consumers">
									<Button
										variant="text"
										type="primary"
										className={`menu-btn ${location.pathname === "/inactive-consumers"
											? "--useractive"
											: ""
											}`}
									>
										<span className="app-header-btn-text">
											Inactive Consumers
										</span>
									</Button>
								</Link>
								{role === "Master" && <>
									<Link to="/Reseller">
										<Button
											variant="text"
											type="primary"
											className={`menu-btn ${location.pathname === "/Reseller"
												? "--useractive"
												: ""
												}`}
										>
											<span className="app-header-btn-text">
												Reseller
											</span>
										</Button>
									</Link>
									<Link to="/sales-representative">
										<Button
											variant="text"
											type="primary"
											className={`menu-btn ${location.pathname === "/sales-representative"
												? "--useractive"
												: ""
												}`}
										>
											<span className="app-header-btn-text">
												Sales Representative
											</span>
										</Button>
									</Link>
								</>}

							</li>
						</ul>
					</div>
					<ul className={`${cl}__profile`}>
						<li className={`${cl}__profile--item`}>
							<Button
								id="basic-button"
								aria-controls={open ? "basic-menu" : undefined}
								aria-haspopup="true"
								aria-expanded={open ? "true" : undefined}
								onClick={handleClick2}
								className="profile-menu-btn"
							>
								<img className="profile-menu-btn-pic" src={image ? image : defaultPicture} />
								<div style={{ display: "flex", flexDirection: "column", fontWeight: "bolder", marginRight: "10px" }}>
									<span style={{ fontSize: "13px" }} className="profile-menu-btn-title">{name}</span>
									<span style={{ display: "flex", alignItems: "center", gap: "2px", fontWeight: 'lighter', fontSize: "10px", textAlign: "start" }} className="profile-menu-btn-title">{role} <VscVerifiedFilled style={{ color: "skyblue" }} /></span>
								</div>
								<img
									className="profile-menu-btn-arrow"
									src="/images/icons/down-arrow.svg"
								/>
							</Button>
							<Menu
								id="basic-menu"
								anchorEl={anchorEl2}
								open={open2}
								onClose={handleClose2}
								MenuListProps={{
									"aria-labelledby": "basic-button",
								}}
							>
								<MenuItem onClick={handleLogout}>Logout</MenuItem>
							</Menu>
						</li>
					</ul>
				</div>
			</div>
		</>
	)
}
export default Header
