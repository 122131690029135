import { useEffect, useState } from "react"
import { MaterialReactTable } from 'material-react-table'
import { Button, ListItemIcon } from "@mui/material"
import { MenuItem } from '@mui/material'
import { AddCircleOutline, DeleteOutline, Edit } from "@mui/icons-material"
import AddConsumer from "../components/AddConsumer"
import Header from "../layouts/Header"
import { instance } from "../utils/api"
import { Tooltip } from "@mui/material"
import { Link, useSearchParams } from "react-router-dom"
import 'react-toastify/dist/ReactToastify.css'
import { FaCreditCard } from "react-icons/fa"
import RenewSubscription from "../components/RenewSubscription"

const cl = 'app-page-ticketlist'

function ConsumerCount() {
	const [apiData, setApiData] = useState([])
	const [triggerRefresh, setTriggerRefresh] = useState(0)
	const [isLoading, setIsLoading] = useState(true)
	// const [loading, setLoading] = useState(false)
	// const [selectedConsumer, setSelectedConsumer] = useState(null)
	const [editMode, setEditMode] = useState(false)
	const [row, setRow] = useState(undefined)
	const [open, setOpen] = useState(false)
	const [renewPopup, setRenewPopup] = useState(false)

	let [searchParams, setSearchParams] = useSearchParams();

	const role = searchParams.get('role');
	const id = searchParams.get('id');
	const name = searchParams.get('name');

	console.log(role)

	const Token = localStorage.getItem("Token")

	const fetchData = async () => {
		try {
			let response 
			if(role === 'reseller'){
				response = await instance.get(`/Superadmin/Consumer/list?reseller_id=${id}`, {
								headers: {
									Authorization: `Bearer ${Token}`,
									'Content-Type': 'application/json',
								}
							})
			}else if (role === 'sales'){
				response = await instance.get(`/Superadmin/Consumer/list?sales_person=${id}`, {
					headers: {
						Authorization: `Bearer ${Token}`,
						'Content-Type': 'application/json',
					}
				})
			}
			
			
			const activeConsumers = response.data.results.filter(consumer => consumer.is_active)


			const resultsWithSerialNumber = activeConsumers.map((result, index) => ({
				...result,
				serialNumber: index + 1,
			}))

			setApiData({ results: resultsWithSerialNumber })
			setApiData({ results: resultsWithSerialNumber })
			// console.log(resultsWithSerialNumber)
			// console.log(apiData.results)
			// console.log(response.data)
		} catch (error) {
			console.error("Error:", error)
		}
		finally {
			setIsLoading(false)
		}
	}

	useEffect(() => {
		fetchData()
	}, [triggerRefresh])

	const Spinner = () => (
		<div className="spinner">

			<div className="spinner-inner"></div>
		</div>
	)

	const columns = [
		// {
		//   accessorKey: "serialNumber",
		//   header: "S.No",
		//   Cell: ({ renderedCellValue, row }) => (
		//     <Tooltip title={`View Consumer ${renderedCellValue}`}>
		//       <Link to={`/consumerview/${row.original.domain}`} className="_1hzz">
		//         <span className="custom-table-cell-text plus">
		//           {/* <span className="plus-sign">+</span> */}
		//           {renderedCellValue}
		//         </span>
		//       </Link>
		//     </Tooltip>
		//   ),
		// },


		{
			accessorKey: "name",
			header: "Name",
			Cell: ({ renderedCellValue, row }) => (
				<Tooltip title={`View Consumer ${renderedCellValue}`}>
					<Link to={`/consumerview/${row.original.domain}`} className="_1hzz">
						<span className="custom-table-cell-text plus">
							{/* <span className="plus-sign">+</span> */}
							{renderedCellValue}
						</span>
					</Link>
				</Tooltip>
			),
		},
		{
			accessorKey: "full_name",
			header: "FullName",
			Cell: ({ renderedCellValue }) => (
				<span className="custom-table-cell-text">{renderedCellValue}</span>
			),
		},
		{
			accessorFn: (row) => `${row.employees?.active+row.employees?.in_active}`,
			id:'employees',
			header: "Employees",
			Cell: ({ renderedCellValue, row}) => (
				<span className="custom-table-cell-text">{renderedCellValue}</span>
			),
		},
		{
			accessorKey: "contact_email",
			header: "Email",
			Cell: ({ renderedCellValue }) => (
				<span className="custom-table-cell-text">{renderedCellValue}</span>
			),
		},
		{
			accessorKey: "contact_number",
			header: "Contact",
			Cell: ({ renderedCellValue }) => (
				<span className="custom-table-cell-text">{renderedCellValue}</span>
			),
		},
		{
			accessorKey: "domain",
			header: "Domain",
			Cell: ({ renderedCellValue }) => (
				<span className="custom-table-cell-text">{renderedCellValue}</span>
			),
		},
		{
			accessorKey: "website",
			header: "Website",
			Cell: ({ renderedCellValue }) => (
				<span className="custom-table-cell-text">{renderedCellValue}</span>
			),
		},
		{
			accessorKey: "country_id.name",
			header: "Country",
			Cell: ({ renderedCellValue }) => (
				<span className="custom-table-cell-text">{renderedCellValue}</span>
			),
		},
		{
			accessorKey: "emp_id_code",
			header: "Employee ID",
			Cell: ({ renderedCellValue }) => (
				<span className="custom-table-cell-text">{renderedCellValue}</span>
			),
		},
		{
			accessorKey: "pan",
			header: "Pan Card",
			Cell: ({ renderedCellValue }) => (
				<span className="custom-table-cell-text">{renderedCellValue}</span>
			),
		},


	]

	const months = [
		'January', 'February', 'March', 'April', 'May', 'June',
		'July', 'August', 'September', 'October', 'November', 'December',
	]

	const updatedColumns = [...columns]
	const actionsColumn = updatedColumns.find((col) => col.accessorKey === "actions")

	if (actionsColumn) {
		updatedColumns.splice(updatedColumns.indexOf(actionsColumn), 1)
		updatedColumns.push(actionsColumn)
	}

	console.log(apiData);

	return (
		<>
			<AddConsumer isopen={open} setPopup={() => { setOpen(false); setEditMode(false) }} editMode={editMode} row={row} setTriggerRefresh={setTriggerRefresh} />
			<RenewSubscription isopen={renewPopup} setPopup={setRenewPopup} row={row} setTriggerRefresh={setTriggerRefresh} />
			<div className="agents-list">
				<Header />
			</div>
			<div className='app-page-body'>
				<div className='app-page-wrapper'>
					<div className={`${cl}`}>
						<div className={`${cl}__header`}>
							<h2 className={`${cl}__header--title`}>Consumers of {name}</h2>
							<ul className={`${cl}__header--actions`}>
								<li className={`${cl}__header--actionsitem`}>
									<Button variant="contained" onClick={() => { setOpen(true); setEditMode(false) }}><AddCircleOutline /> Add Consumer</Button>
								</li>
							</ul>
						</div>
					</div>
					<div className="app-table-container">
						<div className="app-table-component">
							{isLoading ? (
								<Spinner />
							) : (
								<MaterialReactTable columns={updatedColumns} data={apiData.results || []} enableRowActions
									renderRowActionMenuItems={({ closeMenu, row }) => [
										<MenuItem
											key={0}
											onClick={() => {
												setRow(row)
												setEditMode(true)
												setOpen(true)
												closeMenu()
											}}
											sx={{ m: 0 }}
										>
											<ListItemIcon>
												<Edit />
											</ListItemIcon>
											Edit
										</MenuItem>,
										<MenuItem
											key={0}
											onClick={() => {
												setRow({...row})
												// setEditMode(true)
												setRenewPopup(true)
												closeMenu()
											}}
											sx={{ m: 0 }}
										>
											<ListItemIcon>
												<FaCreditCard />
											</ListItemIcon>
											Renew Subscription
										</MenuItem>,
										
									]}
									positionActionsColumn="last"
								/>

							)}
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
export default ConsumerCount
