import React, { useEffect, useState } from "react";
import { MaterialReactTable } from 'material-react-table';
import { Box, Button, Grid, ListItemIcon, Tooltip } from "@mui/material";
import { MenuItem } from '@mui/material';
import { AddCircleOutline, DeleteOutline, Edit } from "@mui/icons-material";
import Header from "../layouts/Header";
import { instance } from "../utils/api";
import 'react-toastify/dist/ReactToastify.css';
import ResellerDialog from "../layouts/ResellerPopup";
import {  toast } from 'react-toastify'
import { Link } from "react-router-dom";
import CustomDatePicker from "../components/CustomDatePicker"; 

const cl = 'app-page-ticketlist';

function ConsumerTracking() {

    const [apiData, setApiData] = useState([]);
    const [triggerRefresh, setTriggerRefresh] = React.useState(0)
    const [isLoading, setIsLoading] = useState(true);
    const Token = localStorage.getItem("Token");
    const [start_date, setStartDate] = useState(new Date(Date.now() - 24 * 3600 * 1000 * 30))
    const [end_date, setEndDate] = useState(new Date())

  useEffect(() => {
    const fetchData = async () => {
        setIsLoading(true);
        try {
            const response = await instance.get(`/Superadmin/Consumer/tracking?fromDate=${start_date}&toDate=${end_date}`, {
                headers: {
                    Authorization: `Bearer ${Token}`,
                    'Content-Type': 'application/json',
                }
            })
            setApiData(response.data.results)
        } catch (error) {
            console.error("Error:", error)
        }
      finally {
        
        setIsLoading(false);
      }
    };

    fetchData();
  }, [triggerRefresh, start_date, end_date]);

  const Spinner = () => (
    <div className="spinner" style={{marginTop:'100px'}}>
      
      <div className="spinner-inner"></div>
    </div>
  );

  const columns = [
    {
      accessorKey: "full_name",
      header: "Name",
      Cell: ({ renderedCellValue }) => (
        <span className="custom-table-cell-text">{renderedCellValue}</span>
      ),
    },
    {
      accessorKey: "country",
      header: "Country",
      Cell: ({ renderedCellValue }) => (
        <span className="custom-table-cell-text">{renderedCellValue}</span>
      ),
    },
    {
      accessorKey: "domain",
      header: "Domain",
      Cell: ({ renderedCellValue }) => (
        <span className="custom-table-cell-text">{renderedCellValue}</span>
      ),
    },
    {
        accessorFn: (row) => new Date(row.created_at),
        header:'Joining Dte',
        Cell: ({ cell }) => cell.getValue()?.toLocaleDateString(), 
    },
    {
        accessorKey: "is_active",
        header: "Status",
        Cell: ({ cell }) => (
          <Box className="custom-table-cell-text" sx={(theme) => ({
            backgroundColor:
                    cell.getValue()
                      ? theme.palette.success.dark 
                      : theme.palette.error.dark,
                  borderRadius: '0.25rem',
                  textAlign:'center',
                  color: '#fff',
                  maxWidth: '9ch',
                  p: '0.25rem',
          })}>
            {cell.getValue() ? 'Active' : "Inactive"}
          </Box>
        ),
      },
      {
        accessorKey: "CompanyTrackingDataCount",
        header: "Visit Count",
        Cell: ({ renderedCellValue, row }) => (
            <Tooltip title={`View Consumers list ${renderedCellValue}`}>
                <Link to={`/consumersCompanyTracking/${row.original.domain}?start_date=${start_date.getMonth() + 1}-${start_date.getDate()}-${start_date.getFullYear()}&end_date=${end_date.getMonth() + 1}-${end_date.getDate()}-${end_date.getFullYear()}`} className="_1hzz">
                    <span className="custom-table-cell-text plus">
                        {/* <span className="plus-sign">+</span> */}
                        {renderedCellValue}
                    </span>
                </Link>
            </Tooltip>
        ),
    },


  ]

  const [open, setOpen] = useState(false); 
  const [editMode, setEditMode] = useState(false);

  const updatedColumns = [...columns];
  const actionsColumn = updatedColumns.find((col) => col.accessorKey === "actions");

  if (actionsColumn) {
    updatedColumns.splice(updatedColumns.indexOf(actionsColumn), 1);
    updatedColumns.push(actionsColumn);
  }

  const [row, setRow] = useState();

  async function handleStatus(row){
    const data = {
        is_active: !row?.original.is_active
    }
    try {
        const response = await instance.put(`/Superadmin/Author/update/${row?.original._id}`, data,
            {
              headers: {
                Authorization: `Bearer ${Token}`,
                'Content-Type': 'application/json',
              },
            });
        toast.success('Successfully Changed Status!', { position: 'top-center' });
        setTriggerRefresh(prev => prev + 1)
    } catch (error) {
        console.error('Error:', error);
        
    }
    
  }
  
  return (
    <>
      <div className="agents-list">
        <Header />
      </div>
      <div className='app-page-body'>
        <div className='app-page-wrapper'>
          <div className={`${cl}`}>
            <div className={`${cl}__header`}>
              <h2 className={`${cl}__header--title`}> Consumer Tracking</h2>
            </div>
            <Grid container spacing={3}>
                <Grid item md={6} className="customDatePicker">
                    <CustomDatePicker
                        label="Start Date "
                        value={start_date}
                        onChange={(date) => setStartDate(date.toDate())}
                    />
                </Grid>
                <Grid item md={6} className="customDatePicker">
                <CustomDatePicker
                    label="End Date"
                    value={end_date}
                    onChange={(date) => setEndDate(date.toDate())}
                />
                </Grid>
            </Grid>
          </div>
          <div className="app-table-container">
          <div className="app-table-component">
          {isLoading ? (
       
        <Spinner />
        ) : (
            <MaterialReactTable columns={updatedColumns} data={apiData || []} 
                // enableRowActions
            //     renderRowActionMenuItems={({ closeMenu, row }) => [

            //     <MenuItem
            //       key={0}
            //       onClick={() => {
            //         setRow(row)
            //         setEditMode(true)
            //         setOpen(true)
            //         closeMenu();
            //       }}
            //       sx={{ m: 0 }}
            //     >
            //       <ListItemIcon>
            //         <Edit />
            //       </ListItemIcon>
            //       Edit
            //     </MenuItem>,
            //     <MenuItem
            //       key={0}
            //       onClick={() => {
            //         closeMenu();
            //         handleStatus(row);
            //       }}
            //       sx={{ m: 0 }}
            //     >
            //       {/* <ListItemIcon>
            //         <DeleteOutline />
            //       </ListItemIcon> */}
            //       {row?.original.is_active ? 'Mark As Inactive' : 'Mark As Active'}
            //     </MenuItem>,
            //   ]}
            //   positionActionsColumn="last"
            />
            )}
          </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default ConsumerTracking;
