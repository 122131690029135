import React, { useState } from "react"

import { LockOutlined, LoginOutlined, MailOutline, PasswordOutlined,Visibility,VisibilityOff } from "@mui/icons-material"
import { Box, Button, TextField,IconButton,InputAdornment } from "@mui/material"
import { Link } from "react-router-dom"
import { noauthinstance } from '../../utils/api'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useContext } from "react"
import { AuthContext } from "../../App"
import { jwtDecode as decode } from "jwt-decode"

function Login() {
	const [email, setEmail] = useState("")
	const [password, setPassword] = useState("")
	const [loading, setLoading] = useState(false)
	const navigate = useNavigate()
	const [formSubmitted, setFormSubmitted] = useState(false)
	const [showPassword, setShowPassword] = useState(false);
	const Spinner = () => (
		<div className="spinner">
			<div className="spinner-inner"></div>
		</div>
	)

	const { state, actions } = useContext(AuthContext)

	const handleLogin = async () => {
		setFormSubmitted(true)
		if (!email || !password) {
			toast.error("Please enter both email and password.")
			return
		}
		const formdata = {
			email,
			password,
		}
		
		setLoading(true)
		try {
			const response = await noauthinstance.post("/Superadmin/Author/login/", formdata)

			if (response.data && response.data.Token) {
				const Token = response.data.Token
				const userRole = response.data.results.name
				const ID = response.data.results._id

				localStorage.setItem('Token', Token)
				localStorage.setItem('userRole', userRole)
				localStorage.setItem('ID', ID)

				const decoded = decode(Token)
				decoded && decoded.user && actions.setUserdata(decoded.user)
				navigate('/dashboard')
				toast.success("Login successful!");
			}
		} catch (error) {
			console.error("Login failed:", error)
			console.log("Error Details:", error.response.data)
			toast.error(error.response.data.message || "Login failed. Please try again.");
		}
		finally {
			setLoading(false)
		}
	}



	return (
		<>
			<div className="app-page-login">
				<div className="app-page-login__wrapper">
					<div className="app-page-login__card">
						<h2>Login</h2>
						<div className="app-page-login__formgroup">
							<div className="app-page-login__field">
								<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
									<MailOutline sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
									<TextField
										type="email"
										fullWidth
										required
										id="input-email"
										label="Email"
										placeholder="Enter your email"
										variant="standard"
										onChange={(e) => setEmail(e.target.value)}
										error={formSubmitted && !email}
									/>
								</Box>
							</div>
							<div className="app-page-login__field">
								<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
									<LockOutlined sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
									<TextField
										type={showPassword ? "text" : "password"}
										fullWidth
										required
										id="input-password"
										label="Password"
										placeholder="Enter your password"
										variant="standard"
										onChange={(e) => setPassword(e.target.value)}
										error={formSubmitted && !password}
										InputProps={{
											endAdornment: (
											  <InputAdornment position="end">
												<IconButton
												  edge="end"
												  onClick={() => setShowPassword((prev) => !prev)}
												>
												{showPassword ? <Visibility /> : <VisibilityOff />}

												</IconButton>
											  </InputAdornment>
											),
										  }}
									/>
								</Box>
							</div>
							{/* <div className="app-page-login__forgotpassword">
                                <Link to="/forgotpassword">
                                    <Button style={{ padding: 0 }}>
                                        Forgot Password?
                                    </Button>
                                </Link>
                            </div> */}
							<div className="app-page-login__button">
								<Button
									color="primary"
									variant="contained"
									onClick={handleLogin}
									disabled={loading} // Disable the button while loading
								>
									{loading ? <Spinner /> : <LoginOutlined />}Login
								</Button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Login;

