import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import Chart from "react-apexcharts";
import Header from "../layouts/Header";
import { ChartBar } from "../components/Charts";
import { Colors } from "../components/Colors";
import hexRGB from "../components/hexRGB";
import Pureknob from "../components/PureKnob";
import axios from 'axios';
import { instance } from "../utils/api";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from "react-router-dom";

function Dashboard() {
  const [dashboardData, setDashboardData] = useState(null);
  const Token = localStorage.getItem("Token");
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await instance.get("/Superadmin/Consumer/dashboard", {
          headers: {
            Authorization: `Bearer ${Token}`,
            "Content-Type": "application/json",
          },
        });
        setDashboardData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const cl = "app-page-dashboard";
  const barChartData = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    legend: true,
    datasets: [
      {
        label: "Monthly Revenue",
        borderColor: Colors.primary,
        backgroundColor: hexRGB(Colors.primary, 0.2),
        hoverBackgroundColor: hexRGB(Colors.primary, 0.4),
        borderWidth: 1,
        data: [60, 49, 72, 90, 100, 60, 70, 90, 50, 80, 90, 60],
      },
    ],
  };

  return (
    <>
      <div className="agents-list">
        <Header />
      </div>
      <div className="app-page-body">
        <div className="app-page-wrapper">
          <div className={`${cl}`}>
            <div className={`${cl}__cardlist`}>
              <Grid container spacing={2.5}>
                <Grid item md={6} onClick={() => {navigate('/consumers?is_free=true')}} sx={{cursor:'pointer'}}>
                  <div className={`${cl}__carditem --blue`}>
                    <div className={`${cl}__carditem--image`}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={512}
                        height={512}
                        viewBox="0 0 24 24"
                      >
                        <path d="M21.5 22h-19A2.503 2.503 0 0 1 0 19.5v-2a.5.5 0 0 1 .5-.5H1c1.103 0 2-.897 2-2s-.897-2-2-2H.5a.5.5 0 0 1-.5-.5v-2C0 9.122 1.122 8 2.5 8h19c1.378 0 2.5 1.122 2.5 2.5v2a.5.5 0 0 1-.5.5H23a2.002 2.002 0 0 0-1.414 3.414c.377.378.879.586 1.413.586h.5a.5.5 0 0 1 .5.5v2A2.502 2.502 0 0 1 21.5 22zM1 18v1.5c0 .827.673 1.5 1.5 1.5h19c.827 0 1.5-.673 1.5-1.5V18h-.001a2.979 2.979 0 0 1-2.12-.878A2.982 2.982 0 0 1 20 15c0-1.654 1.346-3 3-3v-1.5c0-.827-.673-1.5-1.5-1.5h-19C1.673 9 1 9.673 1 10.5V12c1.654 0 3 1.346 3 3s-1.346 3-3 3z" />
                        <path d="M8.5 10a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 1 0v1a.5.5 0 0 1-.5.5zM8.5 18.461a.5.5 0 0 1-.5-.5V16.27a.5.5 0 0 1 1 0v1.692a.5.5 0 0 1-.5.499zm0-4.23a.5.5 0 0 1-.5-.5v-1.692a.5.5 0 0 1 1 0v1.692a.5.5 0 0 1-.5.5zM8.5 22a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 1 0v1a.5.5 0 0 1-.5.5z" />
                        <path d="M2.5 9a.498.498 0 0 1-.175-.968l15.9-5.937c.773-.29 1.681.144 1.945.923l1.803 5.321a.5.5 0 0 1-.947.321l-1.803-5.321a.511.511 0 0 0-.649-.308l-15.9 5.937A.482.482 0 0 1 2.5 9z" />
                      </svg>
                    </div>
                    <div className={`${cl}__carditem--content`}>
                    <h1>{dashboardData ? dashboardData.free_consumers : ''}</h1>
                    <p>Free Consumers</p>
                  </div>
                  </div>
                </Grid>
               
                <Grid item md={6} onClick={() => {navigate('/consumers?is_free=false')}} sx={{cursor:'pointer'}}>
                  <div className={`${cl}__carditem --green`}>
                    <div className={`${cl}__carditem--image`}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={512}
                        height={512}
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#000"
                          fillRule="evenodd"
                          d="M2 12C2 6.477 6.477 2 12 2c1.595 0 3.105.374 4.445 1.04a1 1 0 1 1-.89 1.791 8 8 0 1 0 4.396 6.279 1 1 0 1 1 1.988-.22c.04.365.061.735.061 1.11 0 5.523-4.477 10-10 10S2 17.523 2 12zm19.707-7.707a1 1 0 0 1 0 1.414l-9 9a1 1 0 0 1-1.414 0l-3-3a1 1 0 1 1 1.414-1.414L12 12.586l8.293-8.293a1 1 0 0 1 1.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                    <div className={`${cl}__carditem--content`}>
                      <h1>{dashboardData ? dashboardData.paid_consumers : ''}</h1>
                      <p>Paid Consumers</p>
                    </div>
                  </div>
                </Grid>

                <Grid item md={12} onClick={() => {navigate('/consumerTracking')}} sx={{cursor:'pointer'}}>
                  <div className={`${cl}__carditem --red`} >
                    <div className={`${cl}__carditem--image`}>
                    <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg">
                      <path d="M648 683c-9 0-17.9-3.4-24.7-10.3L376 425.5 128.7 672.7c-13.7 13.7-35.8 13.7-49.5 0s-13.7-35.8 0-49.5l272-272c13.7-13.7 35.8-13.7 49.5 0L648 598.5l247.3-247.3c13.7-13.7 35.8-13.7 49.5 0s13.7 35.8 0 49.5l-272 272C665.9 679.6 657 683 648 683z" fill="rgb(255 139 79)" stroke-width="2"/>
                      <path d="M920 682.6c-19.3 0-35-15.7-35-35V411.4H648.8c-19.3 0-35-15.7-35-35s15.7-35 35-35H920c19.3 0 35 15.7 35 35v271.2c0 19.3-15.7 35-35 35z" fill="rgb(255 139 79)" stroke-width="2" /></svg>
                    </div>
                    <div className={`${cl}__carditem--content`} style={{flexDirection:'row', alignItems:'center', justifyContent:'space-between'}}>
                      <h1>Consumers Tracking</h1>
                      <ArrowForwardIcon sx={{fontSize:'30px'}}/>
                    </div>
                  </div>
                </Grid>
               
              </Grid>
            </div>
            <div className={`${cl}__charts`}>
              <Grid container spacing={4}>
                <Grid item md={6}>
                  <div className={`${cl}__piechart`}>
                    <h3 className={`${cl}__piechart--title`}>
                      {" "}
                      Total Consumers
                    </h3>
                    <div className={`${cl}__piechart--content`}>
                      <Chart
                        options={{
                          labels: ["Active Consumers", "Inactive Consumers"],
                          chart: {
                            sparkline: { enabled: false },
                            type: "donut",
                          },
                          dataLabels: {
                            enabled: false,
                          },
                          plotOptions: {
                            pie: {
                              donut: {
                                labels: {
                                  show: true,
                                  name: {
                                    show: true,
                                  },
                                  value: {
                                    show: true,
                                  },
                                  total: {
                                    show: true,
                                    showAlways: true,
                                    formatter: (w) => {
                                      return w.globals.seriesTotals.reduce(
                                        (a, b) => {
                                          return a + b;
                                        },
                                        0
                                      );
                                    },
                                  },
                                },
                              },
                            },
                          },
                          colors: ["rgb(10, 149, 15)", " rgb(255, 0, 0)"],

                          legend: {
                            show: true,
                          },
                        }}
                        series={[
                          dashboardData ? dashboardData.active_consumers : 0, 
                          dashboardData ? dashboardData.in_active__consumers : 0, 
                        ]}
                        type="donut"
                        width="380"
                      />
                    </div>
                  </div>
                </Grid>
                <Grid item md={6}>
                  <div className={`${cl}__piechart`}>
                    <h3 className={`${cl}__piechart--title`}>
                      Total Employees
                    </h3>
                    <div className={`${cl}__piechart--content`}>
                      <Chart
                        options={{
                          labels: ["Active Employees", "Inactive Employees"],
                          chart: {
                            sparkline: { enabled: false },
                            type: "donut",
                          },
                          dataLabels: {
                            enabled: false,
                          },
                          plotOptions: {
                            pie: {
                              donut: {
                                labels: {
                                  show: true,
                                  name: {
                                    show: true,
                                  },
                                  value: {
                                    show: true,
                                  },
                                  total: {
                                    show: true,
                                    showAlways: true,
                                    formatter: (w) => {
                                      return w.globals.seriesTotals.reduce(
                                        (a, b) => {
                                          return a + b;
                                        },
                                        0
                                      );
                                    },
                                  },
                                },
                              },
                            },
                          },
                          colors: ["rgb(26, 188, 156)", "rgb(235,39,68)"],

                          legend: {
                            show: true,
                          },
                        }}
                        series={[
                          dashboardData ? dashboardData.active_employees : 0, 
                          dashboardData ? dashboardData.in_active_employees : 0, 
                        ]}
                        type="donut"
                        width="380"
                      />
                    </div>
                  </div>
                </Grid>
               
                <Grid item md={4}>
                <div className={`${cl}__piechart ${cl}-checkin-employees`}>
                <p className="small">Today Checkin Employees</p>
                <Pureknob
                    data={{
                      size: 120,
                      value: dashboardData ? dashboardData.today_check_in : 0,
                      angleOffset: -0.5,
                      angleStart: 0.5,
                      angleEnd: 0.5,
                      colorFg: Colors.info,
                    }}
                    className="nk-chart-ecommerce-knob"
                  />
                     </div>
                  </Grid>
                  <Grid item md={8} >
                  <div className={`${cl}__piechart`} style={{'height':'300px'}}>
                  <ChartBar data={barChartData} />
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Dashboard;
