import React, { useEffect, useMemo, useState } from 'react';
import { MaterialReactTable } from 'material-react-table';
import { instance } from '../utils/api';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { Box, Grid, Tooltip } from '@mui/material';
import CustomDatePicker from '../components/CustomDatePicker';
import Header from '../layouts/Header';

const cl = 'app-page-ticketlist';

const ConsumersCompanyTracking = () => {
  //data and fetching state
  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);

  //table state
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 1,
    pageSize: 10,
  });

  // general States
  const {domain} = useParams();
  let [searchParams] = useSearchParams();
  const [start_date, setStartDate] = useState(new Date(searchParams.get('start_date')))
  const [end_date, setEndDate] = useState(new Date(searchParams.get('end_date')))


  useEffect(() => {
    const fetchData = async () => {
      if (!data.length) {
        setIsLoading(true);
      } else {
        setIsRefetching(true);
      }
      const path = `/Superadmin/Consumer/tracking/${domain}?fromDate=${start_date}&toDate${end_date}`;
      const url = `${path}&page=${pagination.pageIndex}&page_size=${pagination.pageSize}`;

    //   url.searchParams.set('page',`${pagination.pageIndex}`);
    //   url.searchParams.set('page_size', `${pagination.pageSize}`);
    //   url.searchParams.set('filters', JSON.stringify(columnFilters ?? []));
    //   url.searchParams.set('globalFilter', globalFilter ?? '');
    //   url.searchParams.set('sorting', JSON.stringify(sorting ?? []));
      try {
        const response = await instance.get(url);
        // const json = await response.json();
        setData(response.data.results);
        setRowCount(response.data.noOfRecord);
      } catch (error) {
        setIsError(true);
        console.error(error);
        return;
      }
      setIsError(false);
      setIsLoading(false);
      setIsRefetching(false);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    columnFilters,
    globalFilter,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
    start_date,
    end_date
  ]);

  const columns = useMemo(
    () => [
        {
            accessorKey: "emp_id",
            header: "Employee Id",
            Cell: ({ renderedCellValue }) => (
              <span className="custom-table-cell-text">{renderedCellValue}</span>
            ),
          },
          {
            accessorKey: "module",
            header: "Module",
            Cell: ({ renderedCellValue }) => (
              <span className="custom-table-cell-text">{renderedCellValue}</span>
            ),
          },
        //   {
        //     accessorKey: "domain",
        //     header: "Domain",
        //     Cell: ({ renderedCellValue }) => (
        //       <span className="custom-table-cell-text">{renderedCellValue}</span>
        //     ),
        //   },
          {
              accessorFn: (row) => new Date(row.created_at),
              header:'Date',
              Cell: ({ cell }) => cell.getValue()?.toLocaleDateString(), 
          }
    ],
    [],
  );

  return (
    <>
        <div className="agents-list">
        <Header />
        </div>
        <div className='app-page-body'>
            <div className='app-page-wrapper'>
            <div className={`${cl}`}>
                <div className={`${cl}__header`}>
                <h2 className={`${cl}__header--title`}> {domain} - Tracking</h2>
                </div>
                <Grid container spacing={3}>
                    <Grid item md={6} className="customDatePicker">
                        <CustomDatePicker
                            label="Start Date "
                            value={start_date}
                            onChange={(date) => setStartDate(date.toDate())}
                        />
                    </Grid>
                    <Grid item md={6} className="customDatePicker">
                    <CustomDatePicker
                        label="End Date"
                        value={end_date}
                        onChange={(date) => setEndDate(date.toDate())}
                    />
                    </Grid>
                </Grid>
            </div>
            <div className="app-table-container">
            <div className="app-table-component">
                <MaterialReactTable
                columns={columns}
                data={data}
                // manualFiltering
                manualPagination
                // manualSorting
                muiToolbarAlertBannerProps={
                    isError
                    ? {
                        color: 'error',
                        children: 'Error loading data',
                        }
                    : undefined
                }
                onColumnFiltersChange={setColumnFilters}
                onGlobalFilterChange={setGlobalFilter}
                onPaginationChange={setPagination}
                onSortingChange={setSorting}
                rowCount={rowCount}
                state={{
                    columnFilters,
                    globalFilter,
                    isLoading,
                    pagination,
                    showAlertBanner: isError,
                    showProgressBars: isRefetching,
                    sorting,
                }}
                />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConsumersCompanyTracking;