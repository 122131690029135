import React, { useEffect, useMemo, useState } from "react";
import { MaterialReactTable } from 'material-react-table';
import { Autocomplete, Button, FormControl, Grid, IconButton, ListItemIcon, TextField } from "@mui/material";
import { MenuItem } from '@mui/material';
import { BusinessOutlined, Cancel, Description, DescriptionOutlined, HelpOutline, MailOutline, Person, PersonOutline, PhoneOutlined, SubjectOutlined } from "@mui/icons-material";
import { AddCircleOutline, Close, DeleteOutline, Edit, Send } from "@mui/icons-material";
import AddConsumer from "../components/AddConsumer";
import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';
import Header from "../layouts/Header";
import { instance } from "../utils/api";
import { DatePicker } from '@mui/lab';
import { Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));
const cl = 'app-page-ticketlist';

const data = [
  {
    agentName: "John abraham",
    agentEmail: "agent@gmail.com",
    agentPhone: "9900990099",
    numOfTickets: 30,
    numOfTicketsClosed: 30,
  },
];
function Inactiveconsumers() {

  const [apiData, setApiData] = useState([]);
  const [triggerRefresh, setTriggerRefresh] = React.useState(0)
  const [serialNumbers, setSerialNumbers] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const Token = localStorage.getItem("Token")
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await instance.get("/Superadmin/Consumer/list", {
          headers: {
            Authorization: `Bearer ${Token}`,
            'Content-Type': 'application/json',
          }
        });
        const inactiveConsumers = response.data.results.filter(consumer => !consumer.is_active);

        // Add serial number to the filtered consumers
        const resultsWithSerialNumber = inactiveConsumers.map((result, index) => ({
          ...result,
          serialNumber: index + 1,
        }));
  
        setApiData({ results: resultsWithSerialNumber });
        console.log(resultsWithSerialNumber);
        console.log(apiData.results);
        console.log(response.data);
      } catch (error) {
        console.error("Error:", error);
      }
      finally {
        
        setIsLoading(false);
      }
    };

    fetchData();
  }, [triggerRefresh]);
  const Spinner = () => (
    <div className="spinner">
      
      <div className="spinner-inner"></div>
    </div>
  );

  const columns = [
    // {
    //   accessorKey: "serialNumber",
    //   header: "S.No",
    //   Cell: ({ renderedCellValue, row }) => (
    //     <Tooltip title={`View Consumer ${renderedCellValue}`}>
    //       <Link to={`/consumerview/${row.original.domain}`} className="_1hzz">
    //         <span className="custom-table-cell-text plus">
    //           <span className="plus-sign">+</span>
    //           {renderedCellValue}
    //         </span>
    //       </Link>
    //     </Tooltip>
    //   ),
    // },


    {
      accessorKey: "name",
      header: "Name",
      Cell: ({ renderedCellValue, row }) => (
        <Tooltip title={`View Consumer ${renderedCellValue}`}>
          <Link to={`/consumerview/${row.original.domain}`} className="_1hzz">
            <span className="custom-table-cell-text plus">
        
              {renderedCellValue}
            </span>
          </Link>
        </Tooltip>
      ),
    },
    {
      accessorKey: "full_name",
      header: "FullName",
      Cell: ({ renderedCellValue }) => (
        <span className="custom-table-cell-text">{renderedCellValue}</span>
      ),
    },
    {
      accessorKey: "contact_email",
      header: "Email",
      Cell: ({ renderedCellValue }) => (
        <span className="custom-table-cell-text">{renderedCellValue}</span>
      ),
    },
    {
      accessorKey: "contact_number",
      header: "Contact",
      Cell: ({ renderedCellValue }) => (
        <span className="custom-table-cell-text">{renderedCellValue}</span>
      ),
    },
    {
      accessorKey: "domain",
      header: "Domain",
      Cell: ({ renderedCellValue }) => (
        <span className="custom-table-cell-text">{renderedCellValue}</span>
      ),
    },
    {
      accessorKey: "website",
      header: "Website",
      Cell: ({ renderedCellValue }) => (
        <span className="custom-table-cell-text">{renderedCellValue}</span>
      ),
    },
    {
      accessorKey: "country_id.name",
      header: "Country",
      Cell: ({ renderedCellValue }) => (
        <span className="custom-table-cell-text">{renderedCellValue}</span>
      ),
    },
    {
      accessorKey: "emp_id_code",
      header: "Employee ID",
      Cell: ({ renderedCellValue }) => (
        <span className="custom-table-cell-text">{renderedCellValue}</span>
      ),
    },


  ]

  const [open, setOpen] = useState(false); 
  const [editMode, setEditMode] = useState(false);

  const updatedColumns = [...columns];
  const actionsColumn = updatedColumns.find((col) => col.accessorKey === "actions");

  if (actionsColumn) {
    updatedColumns.splice(updatedColumns.indexOf(actionsColumn), 1);
    updatedColumns.push(actionsColumn);
  }

  const [row, setRow] = useState();
  
  return (
    <>
      <AddConsumer isopen={open} setPopup={() => {setOpen(false); setEditMode(false)}} setTriggerRefresh={setTriggerRefresh} editMode={editMode} row={row}/>
      <div className="agents-list">
        <Header />
      </div>
      <div className='app-page-body'>
        <div className='app-page-wrapper'>
          <div className={`${cl}`}>
            <div className={`${cl}__header`}>
              <h2 className={`${cl}__header--title`}> Inactive Consumers List</h2>
              <ul className={`${cl}__header--actions`}>
                {/* <li className={`${cl}__header--actionsitem`}>
                  <Button variant="contained" onClick={() => {setOpen(true); setEditMode(false)}}><AddCircleOutline /> Add Consumer</Button>
                </li> */}
              </ul>
            </div>
          </div>
          <div className="app-table-container">
          <div className="app-table-component">
          {isLoading ? (
       
        <Spinner />
        ) : (
            <MaterialReactTable columns={updatedColumns} data={apiData.results || []} enableRowActions
              renderRowActionMenuItems={({ closeMenu, row }) => [

                <MenuItem
                  key={0}
                  onClick={() => {
                    setRow(row)
                    setEditMode(true)
                    setOpen(true)
                    closeMenu();
                  }}
                  sx={{ m: 0 }}
                >
                  <ListItemIcon>
                    <Edit />
                  </ListItemIcon>
                  Edit
                </MenuItem>,
                
              ]}
              positionActionsColumn="last"
            />
            )}
          </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Inactiveconsumers;
