import { DatePicker } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import CustomDatePicker from '../components/CustomDatePicker'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { BusinessOutlined, Cancel, Description, DescriptionOutlined, HelpOutline, MailOutline, Person, PersonOutline, PhoneOutlined, SubjectOutlined } from "@mui/icons-material"
import { useEffect, useState, useContext, useRef } from "react"
import { MaterialReactTable } from 'material-react-table'
import { Autocomplete, Button, FormControl, Grid, IconButton,FormControlLabel,Radio,
    RadioGroup, FormLabel, InputAdornment, ListItemIcon, TextField, Tooltip } from "@mui/material"
import { MenuItem } from '@mui/material'
import { AddCircleOutline, Close, DeleteOutline, Edit, Send } from "@mui/icons-material"
import Dialog from '@mui/material/Dialog'
import { styled } from '@mui/material/styles'
import dayjs from 'dayjs'
import 'react-toastify/dist/ReactToastify.css'
import { AuthContext } from "../App"
import { toast } from "react-toastify"
import { instance } from "../utils/api"
import debounce from 'lodash/debounce';
import FormHelperText from '@mui/material/FormHelperText';
import axios from 'axios'
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}))

const cl = 'app-page-ticketlist'

const EditConsumerDetails = ({ isopen, setPopup, row, setTriggerRefresh }) => {
    const [name, setName] = useState("")
    const [full_name, setFull_name] = useState('')
    const [domain, setDomain] = useState('')
    const [country, setCountry] = useState('')
    const [website, setWebsite] = useState('')
    const [reg_number, setRegNumber] = useState('')
    const [contact_email, setContactEmail] = useState('')
    const [contact_number, setContactNumber] = useState('')
    const [emp_id_code, setEmpIdCode] = useState('')
    const [pan_card, setPanCard] = useState('')
    const [reseller, setReseller] = useState(undefined)
    const [sales, setSales] = useState(undefined)
    const [logo, setLogo] = useState('')
    const [logo2, setLogo2] = useState('')
    const [financial_start_month, setFinancialStartMonth] = useState('April')
    const [financial_end_month, setFinancialEndMonth] = useState('March')
    const [start_date, setStartDate] = useState('')
    const [expiry_date, setExpiryDate] = useState('')
    const [isStartPickerOpen, setIsStartPickerOpen] = useState(false)
    const [isExpiryPickerOpen, setIsExpiryPickerOpen] = useState(false)
    const [package_id, setPackageId] = useState('')
    const [address, setAddress] = useState('')
    const [is_active, setIsActive] = useState('active')
    const [apiData, setApiData] = useState([])
    const [serialNumbers, setSerialNumbers] = useState()
    const [isLoading, setIsLoading] = useState(true)
    const [loading, setLoading] = useState(false)
    const [addconsumerPopup, setAddconsumerPopup] = useState(false)
    const [selectedConsumer, setSelectedConsumer] = useState(null)
    const [countryList, setCountryList] = useState([])
    const [autocompleteOpen, setAutocompleteOpen] = useState(false)
    const [autocompleteOpen1, setAutocompleteOpen1] = useState(false)
    const [autocompleteOpen2, setAutocompleteOpen2] = useState(false)
    const [resellerList, setResellerList] = useState([])
    const [salesList, setSalesList] = useState([])
    const [domainAvailability, setDomainAvailability] = useState(null);
    const Token = localStorage.getItem("Token")
    const [selectedlogo, setselectedLogo] = useState('');
    const [selectedlogo2, setselectedLogo2] = useState('');
    const [isLogoSelected, setIsLogoSelected] = useState(false);
    const [isLogo2Selected, setIsLogo2Selected] = useState(false);
    const debouncedCheckDomainAvailabilityRef = useRef(null);
    const [selectedRole, setSelectedRole] = useState('none');
    useEffect(() => {
        debouncedCheckDomainAvailabilityRef.current = debounce(async (url) => {
            try {
                const newDomain = url.includes('.vibhohcm.com') ? url : `${url}.vibhohcm.com`;
                const response = await instance.post('/Company/checkDomain', { url: newDomain });

                if (response.status === 201) {
                    console.log('Domain is not available:', response.data);
                    setDomainAvailability(true);
                } else {
                    console.log('Domain is available:', response.data);
                    setDomainAvailability(false);
                }
            } catch (error) {
                console.error('Error checking domain availability:', error);
                setDomainAvailability(false);
            }
        }, 500);


        return () => debouncedCheckDomainAvailabilityRef.current.cancel();
    }, [setDomain, setDomainAvailability]);

    const handleChange = (e) => {
        const value = e.target.value;
        setDomain(value);

        debouncedCheckDomainAvailabilityRef.current(value);
    };
    const [fieldErrors, setFieldErrors] = useState({
        name: false,
        full_name: false,
        domain: false,
        website: false,
        country: false,
        reg_number: false,
        contact_email: false,
        contact_number: false,
        emp_id_code: false,
        logo: false,
        logo2: false,
        financial_start_month: false,
        financial_end_month: false,
        start_date: false,
        expairy_date: false,
        package_id: false,
        address: false,
        is_active: false,
        pan_card: false,
        reseller: false,
        sales: false
    })
    const [packageList, setPackageList] = useState([])
    const [datesAutofilled, setDatesAutofilled] = useState(false)

    const { state, actions } = useContext(AuthContext)

    const userdata = state.userdata

    const token = localStorage.getItem("Token")

    const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December',
    ]

    const fetchPackages = async () => {
        try {
            const response = await instance.get("/Superadmin/Package/list", {
                headers: {
                    Authorization: `Bearer ${Token}`,
                    'Content-Type': 'application/json',
                }
            })
            const packages = response.data.results
            setPackageList(packages)
        } catch (error) {
            console.error("Error:", error)
        }
    }

    const fetchCountry = async () => {
        try {
            const response = await instance.get("/Superadmin/Country/list", {
                headers: {
                    Authorization: `Bearer ${Token}`,
                    'Content-Type': 'application/json',
                }
            })
            setCountryList(response.data.results)
        } catch (error) {
            console.error("Error:", error)
        }
    }

    const fetchResellers = async () => {
        try {
            const response = await instance.get("/Superadmin/Author/list?role=Reseller", {
                headers: {
                    Authorization: `Bearer ${Token}`,
                    'Content-Type': 'application/json',
                }
            })
            setResellerList(response.data.results)
        } catch (error) {
            console.error("Error:", error)
        }
    }

    const fetchSales = async () => {
        try {
            const response = await instance.get("/Superadmin/Author/list?role=Sales", {
                headers: {
                    Authorization: `Bearer ${Token}`,
                    'Content-Type': 'application/json',
                }
            })
            setSalesList(response.data.results)
        } catch (error) {
            console.error("Error:", error)
        }
    }



    const formattedDomain = String(domain).includes('.vibhohcm.com') ? domain : `${domain}.vibhohcm.com`

    const formData = {
        name: name,
        full_name: full_name,
        domain: formattedDomain,
        website: website,
        country: country,
        reg_number: reg_number,
        contact_email: contact_email,
        contact_number: contact_number,
        emp_id_code: emp_id_code,
        pan: pan_card,
        reseller: reseller,
        sales_person: sales,
        logo: logo ? logo.join(',') : "",
        logo2: logo2 ? logo2.join(',') : "",
        financial_start_month: months.indexOf(financial_start_month) + 1,
        financial_end_month: months.indexOf(financial_end_month) + 1,
        start_date: new Date(start_date).getFullYear() + "-" + (new Date(start_date).getMonth() + 1) + "-" + new Date(start_date).getDate(),
        expairy_date: new Date(expiry_date).getFullYear() + "-" + (new Date(expiry_date).getMonth() + 1) + "-" + new Date(expiry_date).getDate(),
        package_id: package_id,
        address: address,
        is_active: is_active === 'active',
    }

    const errors = {}

    Object.keys(formData).forEach(fieldName => {
        if (!formData[fieldName]) {
            errors[fieldName] = true
        }
        errors.pan_card = false
        errors.reseller = false
        errors.sales = false
    })


    // setFieldErrors(errors)

    const Spinner = () => (
        <div className="spinner">

            <div className="spinner-inner" style={{width:'30px', height:'30px'}}></div>
        </div>
    )
    const validImageTypes = ["image/jpeg", "image/png", "image/jpg"]
    const handleLogoUpload = (event) => {
        try {
            const files = event.target.files;
            if (files.length > 1) {
                setAttachmentError('Please select only one file.')
                return
            }
    
            const invalidFiles = Array.from(files).filter(
                (file) => !validImageTypes.includes(file.type)
            )
    
            if (invalidFiles.length > 0) {
                setAttachmentError('Invalid file type. Only JPG, JPEG, and PNG are allowed.')
                return
            }
    
            setAttachmentError(null)
    
            if (!country) {
                toast.warning('Please select a country before uploading an image.', { position: toast.POSITION.TOP_RIGHT });
                return;
            }

            setLogo((prevFiles) => [...prevFiles, ...files]);
            setIsLogoSelected(true);
            toast.info(`Image selected for upload.`, { position: toast.POSITION.TOP_RIGHT });
        } catch (error) {
            console.error("Error selecting file:", error);

            toast.error('Error selecting file. Please try again.', { position: toast.POSITION.TOP_RIGHT });
        }
    };


    const handleSecondLogoUpload = async (event) => {
        try {
            const files = event.target.files;
            if (files.length > 1) {
                setAttachmentError1('Please select only one file.')
                return
            }
    
            const invalidFiles = Array.from(files).filter(
                (file) => !validImageTypes.includes(file.type)
            )
    
            if (invalidFiles.length > 0) {
                setAttachmentError1('Invalid file type. Only JPG, JPEG, and PNG are allowed.')
                return
            }
    
            setAttachmentError1(null)
            if (!country) {
                toast.warning('Please select a country before uploading an image.', { position: toast.POSITION.TOP_RIGHT });
                return;
            }
            setLogo2((prevFiles) => [...prevFiles, ...files]);
            setIsLogo2Selected(true);
            toast.info(`Image selected for upload.`, { position: toast.POSITION.TOP_RIGHT });
        } catch (error) {
            console.error("Error selecting file:", error);

            toast.error('Error selecting file. Please try again.', { position: toast.POSITION.TOP_RIGHT });
        }
    };

    const [attachmentError, setAttachmentError] = useState(false)
    const [attachmentError1, setAttachmentError1] = useState(false)

    const handleFileInputClick = () => {
        const fileInput = document.getElementById('logo-upload-input');
        if (fileInput) {
            fileInput.click();
        }
    };
    const handleSecondLogoFileInputClick = () => {
        const fileInput = document.getElementById('second-logo-upload-input');
        if (fileInput) {
            fileInput.click();
        }
    };


    const handleRemoveLogo = (index) => {
        setLogo((prevFiles) => prevFiles.filter((file, i) => i !== index));
        setIsLogoSelected(false);
    };
    const handleRemoveLogo2 = (index) => {
        setLogo2((prevFiles) => prevFiles.filter((file, i) => i !== index));
        setIsLogo2Selected(false);
    };
    const validateContactNumber = (value) => {
        const isValid = /^\d+$/.test(value) && value.length > 7;
        return isValid;
    };

    const handleContactNumberBlur = () => {

        const isValid = validateContactNumber(contact_number);
        setFieldErrors((prevErrors) => ({ ...prevErrors, contact_number: !isValid }));
    };

    const validateEmail = (email) => {

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleContactEmailBlur = () => {

        const isValid = validateEmail(contact_email);
        setFieldErrors((prevErrors) => ({ ...prevErrors, contact_email: !isValid }));
    };

    const isFormValid = () => {
        return (
            name &&
            full_name &&
            domain &&
            country &&
            contact_email &&
            contact_number &&
            emp_id_code &&
            website &&
            reg_number &&
            // logo &&
            // logo2 &&
            financial_start_month &&
            financial_end_month &&
            start_date &&
            expiry_date &&
            package_id &&
            address &&
            is_active
        );
    };
    const handleSaveChanges = async () => {
        let selectedlogo, selectedlogo2;
        setLoading(true);
        if (attachmentError || attachmentError1) {
            toast.error('Invalid file upload.Please upload again', { position: toast.POSITION.TOP_RIGHT });
            setLoading(false);
            return;
        }
    
        
        if (Object.values(fieldErrors).some(error => error)) {
            toast.error('Please check errors in the form before saving changes.', { position: toast.POSITION.TOP_RIGHT });
            setLoading(false);
            return;
        }
        if (domainAvailability === true) {
            toast.error('The domain is not available. Please choose a different domain.', { position: toast.POSITION.TOP_RIGHT });
            setLoading(false);
            return;
        }
        if (logo && logo.length > 0) {
            const ssss = countryList.find(item => item.code === country);
            const uploadLogoUrl = `${ssss.website_testing}/welcome/common_upload?file`;

            for (let i = 0; i < logo.length; i++) {
                const file = logo[i];


                try {
                    const formData = new FormData();
                    formData.append('file_type', 'consumer_logo');
                    formData.append('file', file);

                    const response = await instance.post(`Common/Upload`, formData, {
                        headers:{
                            url:row.domain,
                            Authorization: `Bearer ${token}`,
                        }
                    });
                    console.log(response.data.Location);
                    selectedlogo = response.data.Location;
                    // if (response.ok) {
                    //     const responseData = await response.json();
                    //     console.log(responseData)
                    // } else {
                    //     console.error("File upload failed with status:", response.status);

                    //     toast.error('Error uploading image. Please try again.', { position: toast.POSITION.TOP_RIGHT });
                    // }
                } catch (error) {
                    console.error("Error uploading file:", error);

                    toast.error('Error uploading image. Please try again.', { position: toast.POSITION.TOP_RIGHT });
                }
            }
        }

        if (logo2 && logo2.length > 0) {
            const ssss = countryList.find(item => item.code === country);
            const uploadLogo2Url = `${ssss.website_testing}/welcome/common_upload?file`;

            for (let i = 0; i < logo2.length; i++) {
                const file = logo2[i];


                try {
                    const formData = new FormData();
                    formData.append('file_type', 'consumer_logo');
                    formData.append('file', file);

                    const response = await instance.post('Common/Upload', formData, {
                        headers:{
                            url:row.domain,
                            Authorization: `Bearer ${token}`,
                        }
                    });

                    selectedlogo2 = response.data.Location;

                    // if (response.ok) {
                    //     const responseData = await response.json();

                    //     selectedlogo2 = responseData.Location;

                    // } else {
                    //     console.error("File upload failed with status:", response.status);

                    //     toast.error('Error uploading second image. Please try again.', { position: toast.POSITION.TOP_RIGHT });
                    // }
                } catch (error) {
                    console.error("Error uploading file:", error);

                    toast.error('Error uploading second image. Please try again.', { position: toast.POSITION.TOP_RIGHT });
                }
            }
        }
        // const formattedDomain = domain.includes('.vibhohcm.com') ? domain : `${domain}.vibhohcm.com`

        try {
            if (!name ||
                !full_name ||
                !domain ||
                !country ||
                !contact_email ||
                !contact_number ||
                !emp_id_code ||
                !website ||
                !reg_number ||
                // !logo ||
                // !logo2 ||
                !financial_start_month ||
                !financial_end_month ||
                !start_date ||
                !expiry_date ||
                !package_id ||
                !address ||
                !is_active
            ) {
                toast.error('Please fill in all required fields!', { position: 'top-center' })
                return
            }
            setLoading(true)

            const formData = {
                name: name,
                full_name: full_name,
                // domain: formattedDomain,
                website: website,
                // country: country,
                reg_number: reg_number,
                contact_email: contact_email,
                contact_number: contact_number,
                // emp_id_code: emp_id_code,
                pan: pan_card,
                reseller: selectedRole === 'reseller' ? reseller : null,
                sales_person: selectedRole === 'sales' ? sales : null,
                logo: selectedlogo,
                logo2: selectedlogo2,
                financial_start_month: months.indexOf(financial_start_month) + 1,
                financial_end_month: months.indexOf(financial_end_month) + 1,
                start_date: new Date(start_date).getFullYear() + "-" + (new Date(start_date).getMonth() + 1) + "-" + new Date(start_date).getDate(),
                expairy_date: new Date(expiry_date).getFullYear() + "-" + (new Date(expiry_date).getMonth() + 1) + "-" + new Date(expiry_date).getDate(),


                package_id: package_id,
                address: address,
                is_active: is_active === 'active',
            }
            const selectedCountry = countryList.find(item => item.code === country);
            const apiLink = selectedCountry.apilink;

            const response = await axios.put(`${apiLink}/api/Superadmin/Consumer/update/${selectedConsumer.domain}`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            })

            handleClose()

            setSelectedConsumer(null)
            setName('')
            setFull_name('')
            setDomain('')
            setWebsite('')
            setCountry('')
            setRegNumber('')
            setContactEmail('')
            setContactNumber('')
            setEmpIdCode('')
            setPanCard('')
            setReseller('')
            setSales('')
            setLogo('')
            setLogo2('')
            setFinancialStartMonth('April')
            setFinancialEndMonth('March')
            setStartDate('')
            setExpiryDate('')
            setPackageId('')
            setAddress('')
            setIsActive('active')
            handleClose()

            toast.success('Changes saved successfully!', { position: 'top-center' })

            
        } catch (error) {
            console.error("Error:", error);
        
            if (error.response && error.response.data) {
                const serverErrorMessage = error.response.data.error?.error;
                if (serverErrorMessage) {
                    toast.error(serverErrorMessage, { position: 'top-center' });
                    handleClose()
                    return;
                }
            }
        
            toast.error('Error occurred while saving changes!', { position: 'top-center' });
           
            setName('')
            setFull_name('')
            setDomain('')
            setWebsite('')
            setCountry('')
            setRegNumber('')
            setContactEmail('')
            setContactNumber('')
            setEmpIdCode('')
            setPanCard('')
            setReseller('')
            setSales('')
            setLogo('')
            setLogo2('')
            setFinancialStartMonth('April')
            setFinancialEndMonth('March')
            setStartDate('')
            setExpiryDate('')
            setPackageId('')
            setAddress('')
            setIsActive('active')
            handleClose()
        } finally {
            setLoading(false)
        }

        setTriggerRefresh(prev => prev + 1)
    }

    const handleClose = () => {
        setName('')
        setFull_name('')
        setDomain('')
        setWebsite('')
        setCountry('')
        setRegNumber('')
        setContactEmail('')
        setContactNumber('')
        setEmpIdCode('')
        setPanCard('')
        setSelectedRole('none')
        setReseller('')
        setSales('')
        setLogo('')
        setLogo2('')

        setFinancialStartMonth('April')
        setFinancialEndMonth('March')
        setStartDate('')
        setExpiryDate('')
        setPackageId('')
        setAddress('')
        setPopup(false)
        setFieldErrors(false);
        setDomainAvailability(null);
        setIsLogoSelected(false);
        setIsLogo2Selected(false);

    }

    useEffect(() => {
        fetchCountry()
        fetchPackages()

        if (userdata.role === "Master") {
            fetchResellers()
            fetchSales()
        }
        else {
            userdata.role === "Reseller" && setReseller(userdata._id)
            userdata.role === "Sales" && setSales(userdata._id)
        }
    }, [userdata.role])

    // Auto set the start date and end dates according to package
    useEffect(() => {
        // Basic package
        if (package_id) {
            const dayscount = packageList.find(item => item._id === package_id)?.time_loop_for_every_months
            const today = new Date();
            let nextdate = new Date();
            if(row.expairy_date){
                if(new Date(row.expairy_date) > today){
                    setStartDate(new Date(row.expairy_date));
                    nextdate = new Date(row.expairy_date);
                }else{
                    setStartDate(today)
                }
            }else{
                setStartDate(new Date())
            }
            

            nextdate.setDate(nextdate.getDate() + dayscount)
            setExpiryDate(nextdate)
            setDatesAutofilled(true)
        }
    }, [package_id])

    useEffect(() => {
        let timeout

        if (datesAutofilled) {
            timeout = setTimeout(() => {
                setDatesAutofilled(false)
            }, 4000)
        }

        return () => clearTimeout(timeout)
    }, [datesAutofilled])

    useEffect(() => {
        if (row) {
            setSelectedConsumer(row)
       

            setName(row.name)
            setFull_name(row.full_name)
            setDomain(row.domain)
            setWebsite(row.website)
            setCountry(row.country)
            setRegNumber(row.reg_number)
            setContactEmail(row.contact_email)
            setContactNumber(row.contact_number)
            setEmpIdCode(row.emp_id_code)
            setPanCard(row.pan)
            setSelectedRole(row.role || 'none');
            if (row.reseller !== null) {
                setSelectedRole('reseller');
                setReseller(row.reseller?._id);
            } else if (row.sales_person !== null) {
                setSelectedRole('sales');
                setSales(row.sales_person?._id);
            } else {
                setSelectedRole('none');
            }
    
            setFinancialStartMonth(months[row.financial_start_month - 1])
            setFinancialEndMonth(months[row.financial_end_month - 1])
            setStartDate(new Date(row.start_date))
            setExpiryDate(new Date(row.expairy_date))
            setPackageId(row.package_id?._id)
            setAddress(row.address)
            setIsActive(row.is_active ? 'active' : 'inactive')
            // setLogo((row.logo && row.logo.split(',')) || [])
            // setLogo2((row.logo2 && row.logo2.split(',')) || [])
        }
    }, [row])

    return (
        <div className="_7wyb">
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={isopen}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Edit Consumer
                </DialogTitle>
                <IconButton
                    size="small"
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 10,
                        top: 14,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close />
                </IconButton>
                <DialogContent dividers>
                    <div>
                        <div className={`${cl}`}>
                            <div className={`${cl}__card`}>
                                <Grid container spacing={3}>
                                    <Grid item md={6}>
                                        <div className="app-form-fields">
                                            <FormControl fullWidth>
                                                <TextField
                                                    size="medium"
                                                    required
                                                    id="outlined-basic"
                                                    label="Name"
                                                    placeholder="Vibho"
                                                    type="primary"
                                                    variant="outlined"
                                                    value={name}
                                                    onChange={(e) => setName(e.target.value)}
                                                    error={fieldErrors.name}
                                                />
                                            </FormControl>
                                        </div>
                                    </Grid>
                                    <Grid item md={6}>
                                        <div className="app-form-fields">
                                            <FormControl fullWidth>
                                                <TextField
                                                    size="medium"
                                                    required
                                                    id="outlined-basic"
                                                    label="Full Name"
                                                    placeholder="Vibho Technologies Pvt Ltd"
                                                    type="primary"
                                                    variant="outlined"
                                                    value={full_name}
                                                    onChange={(e) => setFull_name(e.target.value)}
                                                    error={fieldErrors.full_name}
                                                />
                                            </FormControl>
                                        </div>
                                    </Grid>
                                    <Grid item md={12}>
                                        <div className="app-form-fields">
                                            <FormControl fullWidth>
                                                <TextField
                                                    size="medium"
                                                    required
                                                    id="domain"
                                                    label="Domain"
                                                    variant="outlined"
                                                    value={domain}
                                                    error={domainAvailability === true}
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end">.vibhohcm.com</InputAdornment>
                                                    }}
                                                    onChange={handleChange}
                                                />

                                            </FormControl>
                                            <FormHelperText
                                                style={{
                                                    color: domainAvailability === true ? 'red' : 'green',
                                                    marginTop: '4px',
                                                    marginLeft: '30px',
                                                }}
                                            >
                                                {domainAvailability === true
                                                    ? 'Domain is not available'
                                                    : domainAvailability === false
                                                        ? 'Domain is available'
                                                        : ''}
                                            </FormHelperText>
                                        </div>
                                    </Grid>


                                    <Grid item md={6}>
                                        <div className="app-form-fields">
                                            <FormControl fullWidth>
                                                <TextField
                                                    size="medium"
                                                    required
                                                    id="country_code"
                                                    label="Country"
                                                    variant="outlined"
                                                    select
                                                    value={country}
                                                    error={fieldErrors.country}
                                                    onChange={(e) => setCountry(e.target.value)}
                                                >
                                                    {countryList.map((index) => (
                                                        <MenuItem key={index._id} value={index.code}>
                                                            {index.name}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </FormControl>
                                        </div>
                                    </Grid>
                                    <Grid item md={6}>
                                        <div className="app-form-fields">
                                            <FormControl fullWidth>
                                                <TextField
                                                    size="medium"
                                                    required
                                                    id="website"
                                                    label="Website"
                                                    placeholder="www.tcs.com"
                                                    variant="outlined"
                                                    value={website}
                                                    error={fieldErrors.website}
                                                    onChange={(e) => setWebsite(e.target.value)}
                                                />
                                            </FormControl>
                                        </div>
                                    </Grid>
                                    <Grid item md={6}>
                                        <div className="app-form-fields">
                                            <FormControl fullWidth>
                                                <TextField
                                                    size="medium"
                                                    required
                                                    id="reg_number"
                                                    label="Registration Number"
                                                    placeholder="#123456789"
                                                    variant="outlined"
                                                    value={reg_number}
                                                    error={fieldErrors.reg_number}
                                                    onChange={(e) => setRegNumber(e.target.value)}
                                                />
                                            </FormControl>
                                        </div>
                                    </Grid>
                                    <Grid item md={6}>
                                        <div className="app-form-fields">
                                            <FormControl fullWidth>
                                                <TextField
                                                    size="medium"
                                                    required
                                                    id="contact_email"
                                                    label="Contact Email"
                                                    placeholder="hr@vibhotech.com"
                                                    variant="outlined"
                                                    value={contact_email}
                                                    error={fieldErrors.contact_email}
                                                    helperText={fieldErrors.contact_email ? 'Invalid email address' : ''}
                                                    onBlur={handleContactEmailBlur}
                                                    onChange={(e) => setContactEmail(e.target.value)}
                                                />
                                            </FormControl>
                                        </div>
                                    </Grid>
                                    <Grid item md={6}>
                                        <div className="app-form-fields">
                                            <FormControl fullWidth>
                                                <TextField
                                                    size="medium"
                                                    required
                                                    id="contact_number"
                                                    type='number'
                                                    onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                                    label="Contact Number"
                                                    placeholder="9898989898"
                                                    variant="outlined"
                                                    value={contact_number}
                                                    error={fieldErrors.contact_number}
                                                    helperText={fieldErrors.contact_number ? 'Invalid contact number' : ''}
                                                    onChange={(e) => setContactNumber(e.target.value)}
                                                    onBlur={handleContactNumberBlur}
                                                />
                                            </FormControl>
                                        </div>
                                    </Grid>
                                    <Grid item md={6}>
                                        <div className="app-form-fields">
                                            <FormControl fullWidth>
                                                <TextField
                                                    size="medium"
                                                    required
                                                    id="emp_id_code"
                                                    onKeyDown={(evt) => !/[0-9a-zA-Z]/.test(evt.key) && evt.preventDefault()}
                                                    label="Employee ID Code"
                                                    placeholder="VTIN"
                                                    variant="outlined"
                                                    value={emp_id_code}
                                                    error={fieldErrors.emp_id_code}
                                                    onChange={(e) => setEmpIdCode(e.target.value)}
                                                />
                                            </FormControl>
                                        </div>
                                    </Grid>
                                    <Grid item md={6}>
                                        <div className="app-form-fields">
                                            <FormControl fullWidth>
                                                <TextField
                                                    size="medium"
                                                    id="pan_card"
                                                    onKeyDown={(evt) => !/[0-9a-zA-Z]/.test(evt.key) && evt.preventDefault()}
                                                    label="pan card"
                                                    placeholder="pan"
                                                    variant="outlined"
                                                    value={pan_card}
                                                    error={fieldErrors.pan_card}
                                                    onChange={(e) => setPanCard(e.target.value)}
                                                />
                                            </FormControl>
                                        </div>
                                    </Grid>
                                    <Grid item md={12}>
                                        <div className="app-form-fields">
                                            <FormControl fullWidth>
                                                <TextField
                                                    size="medium"
                                                    required
                                                    id="address"
                                                    label="Address"
                                                    placeholder="Enter Address"
                                                    variant="outlined"
                                                    value={address}
                                                    error={fieldErrors.address}
                                                    onChange={(e) => setAddress(e.target.value)}
                                                />
                                            </FormControl>
                                        </div>
                                    </Grid>
                                    {userdata.role === "Master" &&
                                        <Grid item md={12}>
                                            <div className="app-form-fields">
                                                <FormControl component="fieldset">
                                                    <FormLabel component="legend">Assign to</FormLabel>
                                                    <RadioGroup row value={selectedRole} onChange={(e) => setSelectedRole(e.target.value)}>
                                                    <FormControlLabel value="none" control={<Radio />} label="None" />
                                                        <FormControlLabel value="reseller" control={<Radio />} label="Reseller" />
                                                        <FormControlLabel value="sales" control={<Radio />} label="Sales Representative" />
                                                       
                                                    </RadioGroup>
                                                </FormControl>
                                            </div>
                                        </Grid>
                                    }
                                    {userdata.role === "Master" && selectedRole === "reseller" &&
                                        <Grid item md={12}>
                                            <div className="app-form-fields">
                                                <FormControl fullWidth>
                                                    <TextField
                                                        size="medium"
                                                        id="reseller"
                                                        label="Reseller"
                                                        variant="outlined"
                                                        select
                                                        value={String(reseller)}
                                                        error={fieldErrors.reseller}
                                                        onChange={(e) => setReseller(e.target.value)}
                                                    >
                                                        {resellerList.map((item) => (
                                                            <MenuItem key={item._id} value={String(item._id)}>
                                                                {item.name}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </FormControl>
                                            </div>
                                        </Grid>
                                    }
                                    {userdata.role === "Master" && selectedRole === "sales" &&
                                        <Grid item md={12}>
                                            <div className="app-form-fields">
                                                <FormControl fullWidth>
                                                    <TextField
                                                        size="medium"
                                                        id="sales-representative"
                                                        label="Sales Representative"
                                                        variant="outlined"
                                                        select
                                                        value={String(sales)}
                                                        error={fieldErrors.sales}
                                                        onChange={(e) => setSales(e.target.value)}
                                                    >
                                                        {salesList.map((item) => (
                                                            <MenuItem key={item._id} value={String(item._id)}>
                                                                {item.name}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </FormControl>
                                            </div>
                                        </Grid>
                                    }
                                    <Grid item md={6}>
                                        <div className="app-form-fields">
                                            <FormControl fullWidth>
                                                <Autocomplete
                                                    id="financial_start_month"
                                                    options={months}
                                                    open={autocompleteOpen1}
                                                    onOpen={() => setAutocompleteOpen1(true)}
                                                    onClose={() => setAutocompleteOpen1(false)}
                                                    value={financial_start_month}
                                                    error={fieldErrors.financial_start_month}
                                                    onChange={(event, newValue) => setFinancialStartMonth(newValue)}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            required
                                                            {...params}
                                                            label="Financial Start Month"
                                                            placeholder="Select or type a month"
                                                            variant="outlined"
                                                        />
                                                    )}
                                                />
                                            </FormControl>
                                        </div>
                                    </Grid>
                                    <Grid item md={6}>
                                        <div className="app-form-fields">
                                            <FormControl fullWidth>
                                                <Autocomplete
                                                    id="financial_end_month"
                                                    options={months}
                                                    open={autocompleteOpen2}
                                                    onOpen={() => setAutocompleteOpen2(true)}
                                                    onClose={() => setAutocompleteOpen2(false)}
                                                    value={financial_end_month}
                                                    error={fieldErrors.financial_end_month}
                                                    onChange={(event, newValue) => setFinancialEndMonth(newValue)}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            required
                                                            {...params}
                                                            label="Financial End Month"
                                                            placeholder="Select or type a month"
                                                            variant="outlined"
                                                        />
                                                    )}
                                                />
                                            </FormControl>
                                        </div>
                                    </Grid>
                                    
                                  
                                    <Grid item md={6}>

                                        <div className="app-form-fields">

                                            <FormControl fullWidth>
                                                <TextField
                                                    size="medium"
                                                    required
                                                    id="package_id"
                                                    label="Package ID"
                                                    variant="outlined"
                                                    select
                                                    value={package_id}
                                                    error={fieldErrors.package_id}
                                                    onChange={(e) => setPackageId(e.target.value)}
                                                >
                                                    {packageList.map((pack) => (
                                                        <MenuItem key={pack._id} value={pack._id}>
                                                            {pack.name}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </FormControl>
                                        </div>
                                    </Grid>


                                    <Grid item md={6}>
                                        <div className="app-form-fields">
                                            <FormControl fullWidth>
                                                <TextField
                                                    size="medium"
                                                    required
                                                    id="is_active"
                                                    label="Is Active"
                                                    variant="outlined"
                                                    select
                                                    value={is_active}
                                                    error={fieldErrors.is_active}
                                                    onChange={(e) => setIsActive(e.target.value)}
                                                >
                                                    <MenuItem value="active">Active</MenuItem>
                                                    <MenuItem value="inactive">Inactive</MenuItem>
                                                </TextField>
                                            </FormControl>

                                        </div>
                                    </Grid>
                                    <Grid item md={6} className="customDatePicker">

                                        <CustomDatePicker
                                            label="Start Date "
                                            value={start_date}
                                            error={fieldErrors.start_date}
                                            onChange={(date) => setStartDate(date.toDate())}
                                        />
                                    </Grid>
                                    <Grid item md={6} className="customDatePicker">
                                        <CustomDatePicker
                                            label="Expiry Date"
                                            value={expiry_date}
                                            error={fieldErrors.expairy_date}
                                            onChange={(date) => setExpiryDate(date.toDate())}
                                        />
                                    </Grid>
                                    {datesAutofilled && <div style={{ fontSize: "12px", color: "green", marginLeft: "24px", marginTop: "4px" }}>Start date and end date are auto-filled according to selected package</div>}
                                    <Grid item sm={6}>
                                        <div className="userform__field">
                                       

                                            <span className="userform__field--label">Company Logo <sup style={{ color: "red" }}>*</sup></span>
                                            <div className='upload-files-box'>
                                            {attachmentError && (
                                                            <div style={{ color: 'red', marginBottom: '5px' }}>
                                                                {attachmentError}
                                                            </div>
                                                        )}
                                                <ul className='upload-files-list'>
                                                    {logo && logo.length > 0 && logo.map((file, index) => (
                                                        <li key={index} className='upload-files-listitem'>
                                                            <Description className='uploadfile-icon' />
                                                            <span className='uploadfile-title'>{file.name}</span>
                                                            <IconButton size='small' onClick={() => handleRemoveLogo(index)}>
                                                                <Cancel />
                                                            </IconButton>
                                                        </li>
                                                    ))}
                                                    <li className='upload-files-listbutton'>
                                                        <input
                                                            id="logo-upload-input"
                                                            type="file"
                                                            style={{ display: 'none' }}
                                                            onChange={handleLogoUpload}
                                                            disabled={isLogoSelected}
                                                        />
                                                        <label htmlFor="logo-upload-input">
                                                            <Tooltip placement="right" title="The recommended image size is 220 pixels by 70 pixels.">
                                                                <Button
                                                                    style={{ border: fieldErrors.logo ? "1px solid red" : undefined }}
                                                                    variant="contained"
                                                                    onClick={handleFileInputClick}

                                                                >
                                                                    <CloudUploadIcon className='uploadfile-icon' />
                                                                    <span className='uploadfile-title'>Upload Logo</span>
                                                                </Button>
                                                            </Tooltip>
                                                        </label>
                                                    </li>
                                                </ul>

                                            </div>
                                        </div>
                                    </Grid>


                                    <Grid item sm={6}>
                                        <div className="userform__field">
                                            <span className="userform__field--label">Company Icon <sup style={{ color: "red" }}>*</sup></span>
                                            <div className='upload-files-box'>
                                            {attachmentError1 && (
                                                            <div style={{ color: 'red', marginBottom: '5px' }}>
                                                                {attachmentError1}
                                                            </div>
                                                        )}
                                                <ul className='upload-files-list'>
                                                    {logo2 && logo2.length > 0 && logo2.map((file, index) => (
                                                        <li key={index} className='upload-files-listitem'>
                                                            <Description className='uploadfile-icon' />
                                                            <span className='uploadfile-title'>{file.name}</span>
                                                            <IconButton size='small' onClick={() => handleRemoveLogo2(index)}>
                                                                <Cancel />
                                                            </IconButton>
                                                        </li>
                                                    ))}
                                                    <li className='upload-files-listbutton'>
                                                        <input
                                                            id="second-logo-upload-input"
                                                            type="file"
                                                            style={{ display: 'none' }}
                                                            onChange={handleSecondLogoUpload}
                                                            disabled={isLogo2Selected}
                                                        />
                                                        <label htmlFor="second-logo-upload-input">
                                                            <Tooltip placement="right" title="The recommended image size is 42 pixels by 42 pixels.">
                                                                <Button
                                                                    style={{ border: fieldErrors.logo2 ? "1px solid red" : undefined }}
                                                                    component="label"
                                                                    variant="contained"
                                                                    onClick={handleSecondLogoFileInputClick}
                                                                >
                                                                    <CloudUploadIcon className='uploadfile-icon' />
                                                                    <span className='uploadfile-title'>Upload Icon</span>
                                                                </Button>
                                                            </Tooltip>
                                                        </label>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Grid>

                                </Grid>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button
                        type="primary"
                        variant="contained"
                        autoFocus
                        onClick={handleSaveChanges}
                        disabled={loading || !isFormValid()} 
                    >
                        {loading && <Spinner />}
                        <span>{loading ? 'Saving changes...' : 'Save changes'}</span>
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </div>
    )
}

export default EditConsumerDetails
