import React, { useEffect, useState } from "react";
import { MaterialReactTable } from 'material-react-table';
import { Box, Button, ListItemIcon, Tooltip } from "@mui/material";
import { MenuItem } from '@mui/material';
import { AddCircleOutline, DeleteOutline, Edit } from "@mui/icons-material";
import Header from "../layouts/Header";
import { instance } from "../utils/api";
import 'react-toastify/dist/ReactToastify.css';
import ResellerDialog from "../layouts/ResellerPopup";
import {  toast } from 'react-toastify'
import { Link } from "react-router-dom";

const cl = 'app-page-ticketlist';

function SalesRepresentative() {

  const [apiData, setApiData] = useState([]);
  const [triggerRefresh, setTriggerRefresh] = React.useState(0)
  const [isLoading, setIsLoading] = useState(true);
  const Token = localStorage.getItem("Token")

  useEffect(() => {
    const fetchData = async () => {
        try {
            const response = await instance.get("/Superadmin/Author/list?role=Sales", {
                headers: {
                    Authorization: `Bearer ${Token}`,
                    'Content-Type': 'application/json',
                }
            })
            setApiData(response.data.results?.reverse())
        } catch (error) {
            console.error("Error:", error)
        }
      finally {
        
        setIsLoading(false);
      }
    };

    fetchData();
  }, [triggerRefresh]);

  const Spinner = () => (
    <div className="spinner">
      
      <div className="spinner-inner"></div>
    </div>
  );

  const columns = [
    {
      accessorKey: "name",
      header: "Name",
      Cell: ({ renderedCellValue }) => (
        <span className="custom-table-cell-text">{renderedCellValue}</span>
      ),
    },
    {
      accessorKey: "email",
      header: "Email",
      Cell: ({ renderedCellValue }) => (
        <span className="custom-table-cell-text">{renderedCellValue}</span>
      ),
    },
    {
      accessorKey: "phone",
      header: "Contact",
      Cell: ({ renderedCellValue }) => (
        <span className="custom-table-cell-text">{renderedCellValue}</span>
      ),
    },
    {
			accessorKey: "SalesConsumerCnt",
			header: "Consumers",
			Cell: ({ renderedCellValue, row }) => (
				<Tooltip title={`View Consumers list ${renderedCellValue}`}>
					<Link to={`/consumersList/?role=sales&name=${row.original.name}&id=${row.original._id}`} className="_1hzz">
						<span className="custom-table-cell-text plus">
							{/* <span className="plus-sign">+</span> */}
							{renderedCellValue}
						</span>
					</Link>
				</Tooltip>
			),
		},
    {
        accessorFn: (row) => new Date(row.created_at),
        header:'Joining DAte',
        Cell: ({ cell }) => cell.getValue()?.toLocaleDateString(), 
    },
    {
        accessorKey: "is_active",
        header: "Status",
        Cell: ({ cell }) => (
          <Box className="custom-table-cell-text" sx={(theme) => ({
            backgroundColor:
                    cell.getValue()
                      ? theme.palette.success.dark 
                      : theme.palette.error.dark,
                  borderRadius: '0.25rem',
                  textAlign:'center',
                  color: '#fff',
                  maxWidth: '9ch',
                  p: '0.25rem',
          })}>
            {cell.getValue() ? 'Active' : "Inactive"}
          </Box>
        ),
      },


  ]

  const [open, setOpen] = useState(false); 
  const [editMode, setEditMode] = useState(false);

  const updatedColumns = [...columns];
  const actionsColumn = updatedColumns.find((col) => col.accessorKey === "actions");

  if (actionsColumn) {
    updatedColumns.splice(updatedColumns.indexOf(actionsColumn), 1);
    updatedColumns.push(actionsColumn);
  }

  const [row, setRow] = useState();

  async function handleStatus(row){
    const data = {
        is_active: !row?.original.is_active
    }
    try {
        const response = await instance.put(`/Superadmin/Author/update/${row?.original._id}`, data,
            {
              headers: {
                Authorization: `Bearer ${Token}`,
                'Content-Type': 'application/json',
              },
            });
        toast.success('Successfully Changed Status!', { position: 'top-center' });
        setTriggerRefresh(prev => prev + 1)
    } catch (error) {
        console.error('Error:', error);
        
    }
    
  }
  
  return (
    <>
      {/* <AddConsumer isopen={open} setPopup={() => {setOpen(false); setEditMode(false)}} editMode={editMode} row={row}/> */}
      <ResellerDialog open={open} onClose={() => {setOpen(false); setEditMode(false); setTriggerRefresh(prev => prev + 1 )}} editMode={editMode} row={row} role="Sales" dynamicTitle="Add sales representative" />
      <div className="agents-list">
        <Header />
      </div>
      <div className='app-page-body'>
        <div className='app-page-wrapper'>
          <div className={`${cl}`}>
            <div className={`${cl}__header`}>
              <h2 className={`${cl}__header--title`}> Sales Representative</h2>
              <ul className={`${cl}__header--actions`}>
                <li className={`${cl}__header--actionsitem`}>
                  <Button variant="contained" onClick={() => {setOpen(true); setEditMode(false)}}><AddCircleOutline /> Add Sales Representative</Button>
                </li>
              </ul>
            </div>
          </div>
          <div className="app-table-container">
          <div className="app-table-component">
          {isLoading ? (
       
        <Spinner />
        ) : (
            <MaterialReactTable columns={updatedColumns} data={apiData || []} enableRowActions
              renderRowActionMenuItems={({ closeMenu, row }) => [

                <MenuItem
                  key={0}
                  onClick={() => {
                    setRow(row)
                    setEditMode(true)
                    setOpen(true)
                    closeMenu();
                  }}
                  sx={{ m: 0 }}
                >
                  <ListItemIcon>
                    <Edit />
                  </ListItemIcon>
                  Edit
                </MenuItem>,
                <MenuItem
                  key={0}
                  onClick={() => {
                    closeMenu();
                    handleStatus(row);
                  }}
                  sx={{ m: 0 }}
                >
                  {/* <ListItemIcon>
                    <DeleteOutline />
                  </ListItemIcon> */}
                  {row?.original.is_active ? 'Mark As Inactive' : 'Mark As Active'}
                </MenuItem>,
              ]}
              positionActionsColumn="last"
            />
            )}
          </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default SalesRepresentative;
