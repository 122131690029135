import axios from 'axios';


const Token = localStorage.getItem("Token")
console.log('....',Token)

console.log({ t: process.env.REACT_APP_BASE })

let baseURL

const environment = process.env.REACT_APP_BASE && process.env.REACT_APP_BASE.trim()

if (environment === "production") {
    baseURL = "https://vibhohcm-api.vibhohcm.com/api"
}
else if (environment === "staging") {
    baseURL = "https://in.dev-api.vibhohcm.com/api"
}
// Development
else {
    baseURL = "https://in.dev-api.vibhohcm.com/api"
}

export const instance = axios.create({
    baseURL: baseURL,
    headers: {
        'Accept': 'application/json',
        Authorization: `Bearer ${Token}`,
    },
});

export const noauthinstance = axios.create({
    baseURL: baseURL,
    headers: {
        'Accept': 'application/json', 
    },
});